import React, {useEffect, useState} from "react";
import './home.scss';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {doGetRequest} from "../../helpers/api-helper";
import {Error as ErrorView} from "../../components/error/error";
import {Loader} from "../loader/loader";
import Bugsnag from "@bugsnag/js";

export const Home = () => {
  const {t} = useTranslation();
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCompanies = () => {
    setIsLoading(true);

    doGetRequest('/companies/fetch')
      .then((json) => {
        if (json.hasOwnProperty('success') && json.success) {
          setCompanies(Object.values(json.companies));
          setError(false);
        } else {
          console.error(json);
          setError(true);

          Bugsnag.notify(new Error('Could not fetch companies'), (e) => {
            e.severity = 'error';
            e.context = 'companies-fetch';
            e.addMetadata('json', json);
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError(true);

        Bugsnag.notify(error);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchCompanies();

    setInterval(() => {
      fetchCompanies();
    }, 60000);
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="home-screen">
      {error && <ErrorView message={error}/>}

      <img src={'/logo-long.svg'} alt={'Westerparkstaete'} />

      <h1>{t('overview_text')}</h1>

      <div className="company-list">
        {companies.map((x) => (
          <Link to={`/company/${x.id}`} className="company-list-item" key={x.id}>
            <div className={"company-list-description"}>
              <span>{x.company_name}</span>
              <span className={"company-description"}>{x.company_short_description}</span>
            </div>

            <div className="icon-wrapper">
              <i className="fal fa-long-arrow-right" />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};