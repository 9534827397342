import React, {useEffect, useState} from "react";
import './ring.scss';
import {Loader} from "../loader/loader";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

export const Ring = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [person, setPerson] = useState(null);
    const [companyColor, setCompanyColor] = useState(null);

    useEffect(() => {
        const storedData = JSON.parse(sessionStorage.getItem('ringData'));

        if (storedData) {
            setPerson(storedData.person);
            setCompanyColor(storedData.company_color);
            sessionStorage.removeItem('ringData');

            setTimeout(() => {
                i18next.changeLanguage('nl').then(() => navigate('/'));
            }, 10000);
        } else {
            navigate('/overview');
        }
    }, [navigate]);

    return !person ? ( <Loader /> ) : (
        <div className="ring-screen">
            <div className="countdown-bar" style={{backgroundColor: companyColor}} />

            <lottie-player src="/check.json" style={{height: '300px'}} background="transparent" speed="1" autoplay />

            <p>{t('ring_start')} <strong>{person.id === 9999 ? t('someone') : person.name}</strong>{t('ring_end')}<br />
                {t('ring_waiting_room')}</p>
        </div>
    );
};