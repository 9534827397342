import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Home} from "./screens/home/home";
import {Overview} from "./screens/overview/overview";
import {Company} from "./screens/company/company";
import {Ring} from "./screens/ring/ring";
import {IdleWrapper} from "./components/idle-wrapper";

function App() {
  return (
    <BrowserRouter>
      <IdleWrapper />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/overview" element={<Overview />} />
        <Route path="/company/:companyId" element={<Company />} />
        <Route path="/complete" element={<Ring />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
