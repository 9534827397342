import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import './company.scss';
import {doGetRequest, doPostRequest} from "../../helpers/api-helper";
import {Loader} from "../loader/loader";
import {useTranslation} from "react-i18next";
import {Error as ErrorView} from "../../components/error/error";
import Bugsnag from "@bugsnag/js";

export const Company = () => {
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [company, setCompany] = useState(null);
    const [people, setPeople] = useState([]);
    const [error, setError] = useState(null);

    const fetchCompany = (companyId) => {
        doGetRequest(`/companies/fetch-id/${companyId}`)
            .then((json) => {
                if (json.hasOwnProperty('success') && json.success) {
                    setCompany(json.company);

                    let newPeopleArray = [...json.company.users];

                    newPeopleArray.sort((a, b) => {
                        if ( a.is_checked_in > b.is_checked_in || ( a.device_token && !b.device_token ) ) {
                            return 1;
                        }

                        if ( b.is_checked_in > a.is_checked_in || ( b.device_token && !a.device_token ) ) {
                            return 0;
                        }

                        return -1;
                    });

                    if (newPeopleArray.filter(p => p.is_checked_in && p.device_token).length > 1) {
                        newPeopleArray = [
                            ...newPeopleArray,
                            {
                                id: 9999,
                                name: t('general'),
                            }
                        ];
                    }

                    setPeople(newPeopleArray);
                    setError(false);
                } else {
                    console.error(json);
                    setError(true);

                    Bugsnag.notify(new Error('Could not fetch company'), (e) => {
                        e.severity = 'error';
                        e.context = 'company-fetch';
                        e.addMetadata('json', json);
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                setError(true);

                Bugsnag.notify(error);
            });
    };

    const ringBell = (personId, name) => {
        doPostRequest('/notifications/store', {
            company_id: company.id,
            user_id: personId,
        })
            .then((json) => {
                if (json.hasOwnProperty('success') && json.success) {
                    sessionStorage.setItem('ringData', JSON.stringify({
                        person: {
                            id: personId,
                            name: name,
                        },
                        company_color: company.company_color,
                    }));
                    navigate('/complete');
                    setError(false);
                } else {
                    console.error(json);
                    setError(true);

                    Bugsnag.notify(new Error('Could not send notification'), (e) => {
                        e.severity = 'error';
                        e.context = 'company-notify';
                        e.addMetadata('json', json);
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                setError(true);

                Bugsnag.notify(error);
            });
    };

    useEffect(() => {
        if (params.companyId) {
            fetchCompany(params.companyId);
        } else {
            navigate('/');
        }
    }, [params, navigate]);

    return !company ? ( <Loader /> ) : (
        <div className="company-screen">
            {error && <ErrorView message={error} />}

            <div className="side-line" style={{ backgroundColor: company.company_color }} />

            <Link to="/" className="back-button">
                <i className="fal fa-chevron-left" />
                {t('back_button')}
            </Link>

            <div className="content-wrapper">
                <div className="company-content">
                    {company.company_logo_url && company.company_logo_url.length ? (
                      <img src={company.company_logo_url} alt={company.company_name} className="company-logo" />
                    ) : (
                      <span className="company-title-text">{company.company_name}</span>
                    )}
                </div>

                <div className="company-title">
                    <h1>Meld je aan</h1>
                    <p>Je laat weten dat je er bent door te klikken op de button met de naam van je contactpersoon. Neem plaats in de wachtruimte en je wordt opgehaald.</p>
                </div>

                <div className="people-wrapper">
                    <div className="people-wrapper-inner">
                        {people.map((person) => (
                            <div
                              className={`person ${( !person.is_checked_in && person.id !== 9999 ) || ( !person.device_token && person.id !== 9999 ) ? 'checkout' : ''}`}
                              key={person.id}
                              onClick={() => ringBell(person.id, person.name)}
                            >
                                <span>{person.name} {( !person.is_checked_in && person.id !== 9999 ) || ( !person.device_token && person.id !== 9999 ) ? '(Afwezig)' : ''}</span>

                                <div className="icon-wrapper">
                                    <i className="fal fa-bell" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="about-wrapper">
                <span className="about-title">Over {company.company_name}</span>
                <p>{company.company_about}</p>
            </div>
        </div>
    );
};