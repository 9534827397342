// const API_URL = 'http://localhost:9000/api';
const API_URL = 'https://api.portal.westerparkstaete.noorderplantage.nl/api';

export const doGetRequest = (url) => {
    return fetch(`${API_URL}${url}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
        },
    })
        .then((response) => response.json());
};

export const doPostRequest = (url, data) => {
    return fetch(`${API_URL}${url}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json());
}