import React from "react";
import './loader.scss';

export const Loader = () => {
    return (
        <div className="loader-screen">
            <img src="/logo-long.svg" alt="Westerparkstaete" />

            <i className="fad fa-3x fa-spinner-third fa-spin" />
        </div>
    );
};