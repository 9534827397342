import {useIdleTimer} from "react-idle-timer";
import {useNavigate} from "react-router-dom";

export const IdleWrapper = () => {
    const navigate = useNavigate();

    const onIdle = () => navigate('/');

    useIdleTimer({
        timeout: 1000 * 60,
        onIdle,
    });

    return null;
};