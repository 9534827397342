import React from "react";
import {useTranslation} from "react-i18next";
import './error.scss';

export const Error = ({ message }) => {
    const { t } = useTranslation();

    return (
        <div className="error-alert">
            <span>{typeof message !== 'boolean' && message ? message : t('error_message')}</span>
        </div>
    );
};