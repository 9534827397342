import React, {useEffect, useState} from "react";
import './overview.scss';
import {Link} from "react-router-dom";
import {doGetRequest} from "../../helpers/api-helper";
import {useTranslation} from "react-i18next";
import {Error} from "../../components/error/error";

export const Overview = () => {
    const { t } = useTranslation();
    const [companies, setCompanies] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        doGetRequest('/companies/fetch')
            .then((json) => {
                if (json.hasOwnProperty('success') && json.success) {
                    setCompanies(json.companies);
                    setError(false);
                } else {
                    console.error(json);
                    setError(true);
                }
            })
            .catch((error) => {
                console.error(error);
                setError(true);
            });
    }, []);

    return (
        <div className="overview-screen">
            {error && <Error message={error} />}

            <h1>{t('overview_text')}</h1>

            <div className="company-list">
                {companies.map((x) => (
                    <Link to={`/company/${x.id}`} className="company-list-item" key={x.id}>
                        <img src={x.company_logo_url ? x.company_logo_url : '/wps-logo.svg'} alt={x.company_name} />
                    </Link>
                ))}
            </div>
        </div>
    );
};